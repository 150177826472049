// Font Awesome 5.13
import './styles/fontawesome/css/travelsteps-fontawesome.css'
import './styles/fontawesome/css/solid.css'
import './styles/fontawesome/css/regular.css'

// Package Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
// Package Leaflet
import 'leaflet/dist/leaflet.css'
// Leaflet-geosearch
import 'leaflet-geosearch/dist/geosearch.css'
// BeautifyMarker
import 'beautifymarker/leaflet-beautify-marker-icon.css'


// style global
import './styles/index.css'


import React from 'react';
import ReactDOM from 'react-dom';

import thunk from 'redux-thunk'

import App from './containers/App'

// Redux Store
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reducers from './reducers'

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())}>
    <App />
  </Provider> 
  , document.getElementById('travelsteps')
)
